import { apiClient } from '@/services';
import { useUserStore } from '@/stores/useUserStore';
import { useCalendarStore } from '@/stores/useCalendarStore';
import { useSaleStore } from '@/stores/useSaleStore';
import { useClaimStore } from '@/stores/useClaimStore';
import dayjs from 'dayjs';

export const SaleService = {
  getSalesChannel(payload: any = {}) {
    const userStore = useUserStore();
    const calendarStore = useCalendarStore();
    const newPayload = {
      ...payload,
      companyName: userStore.userCompany,
      dateFrom: dayjs(calendarStore.calendarDateRange.start).format('YYYY-MM-DD'),
      dateTo: dayjs(calendarStore.calendarDateRange.end).format('YYYY-MM-DD'),
    };
    return apiClient.get('/sale/sales-channel', {
      params: newPayload,
    });
  },
  getSalesChannelHome(payload: any = {}) {
    const userStore = useUserStore();
    const calendarStore = useCalendarStore();
    const newPayload = {
      ...payload,
      companyName: userStore.userCompany,
      dateFrom: dayjs(calendarStore.calendarHomeDateRange.start).format('YYYY-MM-DD'),
      dateTo: dayjs(calendarStore.calendarHomeDateRange.end).format('YYYY-MM-DD'),
    };
    return apiClient.get('/sale/sales-channel', {
      params: newPayload,
    });
  },
  getSalesChannelBySingleDate(payload: any = {}) {
    const userStore = useUserStore();
    const calendarStore = useCalendarStore();
    const startDate = new Date(calendarStore.singleDate?.getTime());
    startDate.setDate(calendarStore.singleDate?.getDate() - 6);
    const newPayload = {
      ...payload,
      companyName: userStore.userCompany,
      dateFrom: dayjs(startDate).format('YYYY-MM-DD'),
      dateTo: dayjs(calendarStore.singleDate).format('YYYY-MM-DD'),
    };
    return apiClient.get('/sale/sales-channel', {
      params: newPayload,
    });
  },
  getSalesReturn(payload: any = {}) {
    const userStore = useUserStore();
    const saleStore = useSaleStore();
    // const calendarStore = useCalendarStore();
    const claimStore = useClaimStore();
    const newPayload = {
      ...payload,
      companyName: userStore.userCompany,
      // dateFrom: dayjs(calendarStore.calendarHomeDateRange.start).format('YYYY-MM-DD'),
      // dateTo: dayjs(calendarStore.calendarHomeDateRange.end).format('YYYY-MM-DD'),
      salesChannel: saleStore.getSelectedSaleChannel ? [saleStore.getSelectedSaleChannel] : [],
      category: claimStore.getSelectedCategories,
    };
    return apiClient.get('/sale/sales-return', {
      params: newPayload,
    });
  },
  getSalesReturnBySingleDate(payload: any = {}) {
    const userStore = useUserStore();
    const saleStore = useSaleStore();
    // const calendarStore = useCalendarStore();
    const claimStore = useClaimStore();
    // const startDate = new Date(calendarStore.singleDate?.getTime());
    // startDate.setDate(calendarStore.singleDate?.getDate() - 6);
    const newPayload = {
      ...payload,
      companyName: userStore.userCompany,
      // dateFrom: dayjs(startDate).format('YYYY-MM-DD'),
      // dateTo: dayjs(calendarStore.singleDate).format('YYYY-MM-DD'),
      salesChannel: saleStore.getSelectedSaleChannel ? [saleStore.getSelectedSaleChannel] : [],
      category: claimStore.getSelectedCategories,
    };
    return apiClient.get('/sale/sales-return', {
      params: newPayload,
    });
  },
  getYearlyGrowth(payload: any = {}) {
    const userStore = useUserStore();
    const saleStore = useSaleStore();
    const claimStore = useClaimStore();
    const newPayload = {
      ...payload,
      companyName: userStore.userCompany,
      salesChannel: saleStore.getSelectedSaleChannel ? [saleStore.getSelectedSaleChannel] : [],
      category: claimStore.getSelectedCategories,
    };
    return apiClient.get('/sale/yearly-growth', {
      params: newPayload,
    });
  },
};