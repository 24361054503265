import { apiClient } from '@/services';
import {
  LoginData,
  RegisterData,
  PasswordForgotData,
} from '@/utils/types/user';
import { setCookie, getCookie } from 'typescript-cookie';

export const UserService = {
  login(payload: LoginData) {
    return apiClient.post('/user/login', payload);
  },

  logout() {
    return apiClient.get('/user/logout');
  },

  register(payload: RegisterData) {
    return apiClient.post('/user/register', payload);
  },

  passwordForgot(payload: PasswordForgotData) {
    return apiClient.post('/user/password-forgot', payload);
  },

  checkToken() {
    if (getCookie('checkToken')) {
      return Promise.resolve();
    }

    const inTwoHours = new Date(new Date().getTime() + 2 * 60 * 60 * 1000);
    setCookie('checkToken', 'token', { expires: inTwoHours });

    return apiClient.get('/user/check-token');
  },
};