import { apiClient } from '@/services';
import { SettingAdjustment } from '@/utils/types/settingAdjustment';

export const SettingAdjustmentService = {
  getSettingAdjustment() {
    return apiClient.get('/setting/adjustment');
  },
  setSettingAdjustment({ productNumber, field, value }: SettingAdjustment) {
    return apiClient.post(`/setting/adjustment?productNumber=${productNumber}&field=${field}&value=${value}`);
  },
};