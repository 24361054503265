import { defineStore } from 'pinia';
import { UserService } from '@/services/UserService';
import {
  LoginData,
  RegisterData,
  PasswordForgotData,
  UserData,
} from '@/utils/types/user';
import { getCookie, setCookie } from 'typescript-cookie';

export const useUserStore = defineStore('user', {
  state: () => ({
    userData: null as UserData | null,
  }),
  actions: {
    async login(payload: LoginData) {
      const response = await UserService.login(payload);
      if (response.status === 200) {
        this.userData = {
          name: response.data.name,
          companyName: response.data.companyName,
        };
        setCookie('user_grant_access', response.data.accessToken);
        setCookie('user_data', JSON.stringify(this.userData));
      }

      return response;
    },

    async logout() {
      const response = await UserService.logout();

      return response;
    },

    async register(payload: RegisterData) {
      const response = await UserService.register(payload);

      return response;
    },

    async passwordForgot(payload: PasswordForgotData) {
      const response = await UserService.passwordForgot(payload);

      return response;
    },

    setUserDataFromCookie() {
      const userDataString = getCookie('user_data') || '{}';
      const userData = JSON.parse(userDataString);
      this.userData = userData;
    },
  },
  getters: {
    userCompany(stateData): string {
      return this.userData?.companyName || '';
    },
  },
});