import { apiClient } from '@/services';
import {
  ClaimOverviewData,
  ClaimCategoryOverviewData,
  ClaimReasonDetailData,
  ClaimDetailData,
  ClaimTopProductData,
} from '@/utils/types/claim';
import { useUserStore } from '@/stores/useUserStore';
import { useCalendarStore } from '@/stores/useCalendarStore';
import { useSaleStore } from '@/stores/useSaleStore';
import dayjs from 'dayjs';

export const ClaimService = {
  getClaimOverview(payload: ClaimOverviewData) {
    const userStore = useUserStore();
    const calendarStore = useCalendarStore();
    const newPayload = {
      ...payload,
      companyName: userStore.userCompany,
      dateFrom: dayjs(calendarStore.calendarDateRange.start).format('YYYY-MM-DD'),
      dateTo: dayjs(calendarStore.calendarDateRange.end).format('YYYY-MM-DD'),
    };
    return apiClient.get('/claim/overview', {
      params: newPayload,
    });
  },

  getClaimCategoryOverview(payload?: ClaimCategoryOverviewData) {
    const userStore = useUserStore();
    const calendarStore = useCalendarStore();
    const newPayload = {
      ...payload,
      companyName: userStore.userCompany,
      dateFrom: dayjs(calendarStore.calendarDateRange.start).format('YYYY-MM-DD'),
      dateTo: dayjs(calendarStore.calendarDateRange.end).format('YYYY-MM-DD'),
    };
    return apiClient.get('/claim/category-overview', {
      params: newPayload,
    });
  },

  getClaimCategoryHomeOverview(payload?: ClaimCategoryOverviewData) {
    const userStore = useUserStore();
    const calendarStore = useCalendarStore();
    const newPayload = {
      ...payload,
      companyName: userStore.userCompany,
      dateFrom: dayjs(calendarStore.calendarHomeDateRange.start).format('YYYY-MM-DD'),
      dateTo: dayjs(calendarStore.calendarHomeDateRange.end).format('YYYY-MM-DD'),
    };
    return apiClient.get('/claim/category-overview', {
      params: newPayload,
    });
  },

  getClaimCategoryOverviewBySingleDate(payload?: ClaimCategoryOverviewData) {
    const userStore = useUserStore();
    const saleStore = useSaleStore();
    const calendarStore = useCalendarStore();
    const startDate = new Date(calendarStore.singleDate?.getTime());
    startDate.setDate(calendarStore.singleDate?.getDate() - 6);
    const newPayload = {
      ...payload,
      companyName: userStore.userCompany,
      dateFrom: dayjs(startDate).format('YYYY-MM-DD'),
      dateTo: dayjs(calendarStore.singleDate).format('YYYY-MM-DD'),
      salesChannel: saleStore.getSelectedSaleChannel ? [saleStore.getSelectedSaleChannel] : [],
    };
    return apiClient.get('/claim/category-overview', {
      params: newPayload,
    });
  },

  getClaimReasonDetail(payload: ClaimReasonDetailData) {
    const userStore = useUserStore();
    const calendarStore = useCalendarStore();
    const newPayload = {
      ...payload,
      companyName: userStore.userCompany,
      dateFrom: dayjs(calendarStore.calendarDateRange.start).format('YYYY-MM-DD'),
      dateTo: dayjs(calendarStore.calendarDateRange.end).format('YYYY-MM-DD'),
    };
    return apiClient.get('/claim/reason-detail', {
      params: newPayload,
    });
  },

  getClaimDetail(payload: ClaimDetailData) {
    const userStore = useUserStore();
    const calendarStore = useCalendarStore();
    const newPayload = {
      ...payload,
      companyName: userStore.userCompany,
      dateFrom: dayjs(calendarStore.calendarDateRange.start).format('YYYY-MM-DD'),
      dateTo: dayjs(calendarStore.calendarDateRange.end).format('YYYY-MM-DD'),
    };
    return apiClient.get('/claim/detail', {
      params: newPayload,
    });
  },

  getClaimTopProduct(payload: ClaimTopProductData) {
    const userStore = useUserStore();
    const calendarStore = useCalendarStore();
    const newPayload = {
      ...payload,
      companyName: userStore.userCompany,
      dateFrom: dayjs(calendarStore.calendarDateRange.start).format('YYYY-MM-DD'),
      dateTo: dayjs(calendarStore.calendarDateRange.end).format('YYYY-MM-DD'),
    };
    return apiClient.get('/claim/top-product', {
      params: newPayload,
    });
  },
};