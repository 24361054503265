import { defineStore } from 'pinia';
import { SaleService } from '@/services/SaleService';
import {
  DataTotalSaleReturn,
  DataGrowth,
} from '@/utils/types/sale';

export const useSaleStore = defineStore('sales', {
  state: () => {
    return {
      salesChannel: [] as string[],
      datasetTotalSaleReturn: [] as DataTotalSaleReturn[], 
      datasetYearlyGrowth: [] as DataGrowth[],
      selectedSaleChannel: '',
    };
  },
  actions: {
    async getSalesChannel(payload: any = {}) {
      const response = await SaleService.getSalesChannel(payload);
      if (response.status === 200) {
        this.salesChannel = response.data;
      }
      return response;
    },
    async getSalesChannelHome(payload: any = {}) {
      const response = await SaleService.getSalesChannelHome(payload);
      if (response.status === 200) {
        this.salesChannel = response.data;
      }
      return response;
    },
    async getSalesReturn(payload: any = {}) {
      const response = await SaleService.getSalesReturn(payload);
      if (response.status === 200) {
        if (response.data) {
          this.datasetTotalSaleReturn = response.data;
        } else {
          this.datasetTotalSaleReturn = [];
        }
      }
      return response;
    },
    async getYearlyGrowth(payload: any = {}) {
      const response = await SaleService.getYearlyGrowth(payload);
      if (response.status === 200) {
        this.datasetYearlyGrowth = response.data;
      }
      return response;
    },
    async setSaleChannel(channel: string = '') {
      this.selectedSaleChannel = channel;
    },
  },
  getters: {
    getSelectedSaleChannel(stateData): string {
      return stateData.selectedSaleChannel;
    },
    getDatasetTotalReturn(stateData): any {
      let datasets = {} as any;
      stateData.datasetYearlyGrowth.forEach((item) => {
        const dateString = item.date.split('-');
        const yearly = dateString[0];
        const monthly = Number(dateString[1]).toString();
        if (Object.keys(datasets).includes(yearly)) {
          datasets[dateString[0]][monthly] = Number(item.totalClaim);
        } else {
          datasets = {
            ...datasets,
            [yearly]: {
              [monthly]: Number(item.totalClaim),
            },
          };
        }
      });
      return datasets;
    },
    getDatasetTotalSale(stateData): any {
      let datasets = {} as any;
      stateData.datasetYearlyGrowth.forEach((item) => {
        const dateString = item.date.split('-');
        const yearly = dateString[0];
        const monthly = Number(dateString[1]).toString();
        if (Object.keys(datasets).includes(yearly)) {
          datasets[dateString[0]][monthly] = Number(item.totalClaim);
        } else {
          datasets = {
            ...datasets,
            [yearly]: {
              [monthly]: Number(item.totalClaim),
            },
          };
        }
      });
      return datasets;
    },
    getHomeReturnRate(stateData): number {
      if (!stateData.datasetTotalSaleReturn) {
        return 0;
      }
      let totalClaim = 0;
      let totalSale = 0;
      stateData.datasetTotalSaleReturn.forEach((item) => {
        totalClaim += Number(item.totalClaim);
        totalSale += Number(item.totalSale);
      });
      if (totalClaim === 0 || totalSale === 0) {
        return 0;
      }
      return (totalClaim/totalSale) * 100;
    },
  },
});